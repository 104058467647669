import React from 'react';

import Fade from "react-reveal/Fade";

const Header = () => {
  return (
    <section className="header" style={{ backgroundImage: 'url("header/01.png")' }}>
        <div className="poddsticker d-none d-lg-block">
          <Fade right>
            <img src="header/antikpodden.png" alt="Antik Podden - Sveriges första podd om antikviteter" />
          </Fade>
        </div>
        <div className="container text-center">
          <div className="row">
            <div className="col">
              <div className="text">
                <Fade up>
                  <h2>Antikviteten</h2>
                </Fade>
                <Fade up>
                  <span className="tagline">Köp & sälj antikviteter online – fynda här!</span>
                </Fade>
                <Fade up>
                <div className="product-categories">
                  <span className="badge badge-secondary ml-3">Möbler</span>
                  <span className="badge badge-secondary ml-3">Konsthantverk</span>
                  <span className="badge badge-secondary ml-3">Silver</span>
                  <span className="badge badge-secondary ml-3">Konst</span>
                </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};

export default Header;